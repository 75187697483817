.SidebarItem {
    color: white;
    margin: .25vh .25vw;
    box-sizing: border-box;
}


.SidebarItem a {
    padding: .25vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    color: white;
    border-bottom: 1px solid transparent;
}

.SidebarItem:hover,
.active {
    background-color: #afe4f7;
}

/*
@media only screen and (min-width: 600px) {
    .SidebarItem {
        padding: 5px 0;
    }
}

@media only screen and (min-width: 1025px) {    
    .SidebarItem a:hover,
    .SidebarItem a.active {
        border-bottom: 1px solid #afe4f7;
    }
} */