.SidebarWrapper{

}

.Sidebar {
    position: fixed;
    width: 280px;
    max-width: 70%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: rgb(4, 0, 10);;
    padding: 0 1vw;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    text-align: center;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    cursor: pointer;
}

.SidebarItemWrapper{
    color: white;
    margin: .25vh .25vw;
    box-sizing: border-box;
}


.SidebarItem {
    padding: .25vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    color: white;
    border-bottom: 1px solid transparent;
}

.SidebarItemWrapper:hover,
.active {
    background-color: #afe4f7;
}

.LogoWrapper {
    box-sizing: border-box;
    padding: 25px;
}

/* @media only screen and (min-width: 1025px) {
    
} */

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}
