.App {
  text-align: center;
}

.Fit {
  height: fit-content;
}

.page-wrapper {
  min-height: 100vh;
  box-sizing: border-box;
  margin: 0;
  padding: 51px 4vw 0;
}

.PageTitle {
  font-weight: 900;
  font-size: 3em;
  margin: 1vh 0;
  text-transform:uppercase;
}
.PageTitle a {
  text-decoration: none;
  color: black
}

.flex {
  display: flex;
  flex-direction: row !important;
  align-items: center;
}

/* use this to get rid of active styling on nav item */
.none {
  display: flex;
  flex-direction: row !important;
  border-bottom: 1px solid transparent !important;
}
.none:hover {
  border-bottom: 1px solid #afe4f7 !important;
}

/* Generic Page styles */
.page-title {
  font-weight: 900;
  font-size: 3em;
  margin: 1vh 0;
  text-transform: uppercase;
}


.auth-btn           { background-color: #6623d3;}
.my-auth-btn        { color: #6623d3;}
.auth-btn:hover     { background-color: #5b1fbd;}

.btn-primary        { background-color: #357ebd;}
.my-btn-primary     { color: #357ebd;}
.btn-primary:hover  { background-color: #2f6ea5;}

.btn-info           { background-color: #5bc0de;}
.my-btn-info        { color: #5bc0de;}
.btn-info:hover     { background-color: #50a6c0;}

.btn-danger         { background-color: #d9534f;}
.my-btn-danger      { color: #d9534f;}
.btn-danger:hover   { background-color: #cc4f4b;}

.color-orange       {color:rgb(255, 220, 124)}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .page-wrapper{
    margin: 0 5vw;
  }
  .page-title {
      margin: 3vh 0;
  }
}