.Home {
    /* min-height: 84vh; */
}

/* Mission statement and shop link */
.MissionContainer {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    /* top:25%; */
    padding: 5vh 2vw;
    display: flex;
    flex-direction: column;
    margin-top: 51px;
}

.MissionHeading {
    font-size: 32px;
    font-weight: bold;
    margin: 2vh 0;
}

.MissionDescription {
    margin: 2vh 0;
}

.MissionButton {
    background-color: rgb(238, 238, 238);
    box-sizing: border-box;
    margin: 2vh 0;
}

.MissionButton a {
    color: black;
    padding: 1.5vh 0;
    display: block;
    font-size: 18px;
    text-decoration: none;
    border:2px solid rgb(81, 115, 94);
}

.MissionButton a:hover {
    border:2px solid rgb(0, 0, 0);
}

/* Main Image */
.ImageContainer {
    display: flex;
    justify-content: center;
    overflow: hidden;
    height: 100vh;
}

.ImageContainer img{
    object-fit: cover;
    width: 100%;
}

/* Statement */
.Statement {
    background-color: aquamarine;
    margin: 0 0 3vh;
    padding: 2vh 0;
    font-size: 18px;
    font-weight: 600;
}

.FeaturedContent {
    display: flex;
    justify-content: space-evenly;
}

.FeaturedContent{
    display: flex;
    flex-direction: column;
    width: 100%;
}
@media only screen and (min-width: 600px) {
    /* For tablets: */

    .FeaturedContent {
        flex-direction: row;
    }

    .MissionButton {
        margin: 2vh auto;
        width: 400px;
    }

    /* .Home {

    }
    .centered {
        padding: 2vh 1vw;
        width: 370px;
        top: 25%;
        left : 35%;
    }
    .shop a b{ 
        font-size: 24px;
    }
    .description {
        font-size: 24px;
    }
    .title {
        font-size: 32px;
    } */
}

@media only screen and (min-width: 1025px) {
    /* For desktop: */
    /* .Home {
   
   }
    .centered {
        width: 570px;
        padding: 3vh 2vw;
        top: 40%;
        left : 30%;
    }

    .shop {
        padding: 1vh 1vw;
    }
    .shop a b{ 
        font-size: 32px;
    }
    .description {
        font-size: 32px;
    }
    .title {
        font-size: 48px;
    } */
}