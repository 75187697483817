
.Btn {
    color: rgb(255, 255, 255);   
    width: 100%;
    margin: .5vh 0;
    cursor: pointer;
}

.BtnDiv {
    padding: .5em 0;
}


.AuthInput {
    color: rgb(255, 255, 255) !important;   
    width: 100%;
    height: 34px;
    margin: 2vh 0;
    background-color: transparent !important;
    border: none;
    border-bottom-color:rgb(23, 4, 53);
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-width: 3px;
    border-bottom-style: solid;
}

.eye {
    margin: auto 2vw;
    width: 20px;
}