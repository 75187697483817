/* Shared styles */
.NavbarWrapper {
    height: 51px;
    width: 100%;
    position: fixed;
    /* top: 0; */
    /* left: 0; */
    background-color: rgb(4, 0, 10);
    padding: 0 2vw;
    box-sizing: border-box;
    z-index: 150;
    color: white; 
    white-space: nowrap;
    border-bottom: 1px solid #afe4f7;
}

.Navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

/* Mobile Navbar */
.Bars {
  cursor: pointer;  
}

/* Logo with Name */
.LogoWrapper {
    margin: 0 2vw;
    height: 100%;
    display: flex;
    align-items: center;
}

.LogoText {
    height: 100%;
    font-size: 1.15em;
    display:flex;
    align-items: center;
    justify-content: center;
}


/* Center Nav links */
.NavItems {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1em;
    height: 100%;
}

/* style links */
.NavItems a {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    color:white;
    text-decoration: none;
    border-bottom: 1px solid transparent;   
}
.NavItemWrapper {
    height: 100%;
    margin: auto 1vw;
    padding: 5px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.NavItem{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    width: 100%;
    padding: 0 .5vw;
    margin: auto 0;
    box-sizing: border-box;
    text-decoration: none;
    color: white;
    border-bottom: 1px solid transparent;
}

.NavItem:hover,
.NavItem.active {
    border-bottom: 1px solid #afe4f7;
}

/* Clear default styles */
ul {
    margin: 0;
    padding: 0;
    height: 100%;
    list-style: none;
}

.Mobile {
    font-size: 1.35em;
}

/* Hide desktop */
.Desktop {display: none}

.Cart {
    display: flex;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
}

.Cart:hover {
    /* border-bottom: 1px solid #afe4f7; */
}

.TotalItems {
    font-size: 1.3em;
    margin-right: .5vw;
    padding: .75vh 0 1vh;
}

svg {
    cursor: pointer;
}

.dropdownButton {
    height: 100%;
    margin: auto 1vw;
    padding: 5px 0;
    box-sizing: border-box;
}

.dropdownButton a {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    width: 100%;
    padding: 0 .5vw;
    margin: auto 0;
    box-sizing: border-box;
    text-decoration: none;
    color: white;
    border-bottom: 1px solid transparent;
}

.dropdownButton p {
    box-sizing: border-box;
    margin: 0;
}

.dropdownContent {
    display: none;
    position: absolute;
    padding-top:51px;
    background-color: #f9f9f9;
    width: 100%;
    min-height: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}



.dropdownButton a:hover,
.dropdownButton a.active,
.cart:active {
    border-bottom: 1px solid #afe4f7;
}

.dropdownContent:hover {
    display: block;
}


/* For tablet */
@media only screen and (min-width: 600px) {
    .Desktop {
        font-size: 1.35em;
    }
    .NavbarWrapper{
        padding: 0 6vw;
    }

    .dropdownButton {
        margin: 0 .25vw;
    }
}


/* For desktop: */
@media only screen and (min-width: 1025px) {    
    .NavbarWrapper{
        padding: 0 10vw;
    }

    .Desktop {
        display: block;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .Mobile {
        display: none;
    }

    /* .dropdownButton a:hover,
    .dropdownButton a:active {
        border-bottom: 1px solid #afe4f7;
    }
   
    .dropdownButton a:active {
        border-bottom: 1px solid #afe4f7;
    } */
} 