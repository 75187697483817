.Logo {
    /* padding: 2vh 2vw; */
    display: block;
    box-sizing: border-box;
  }



.Logo img {
    height: 100%;
    box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
    .Logo {
      animation: App-logo-spin infinite 55s linear;
    }
}
  
@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  